import {
  CheckCircleOutline,
  CircleOutlined,
  Comment,
  FileOpen,
  HelpOutline,
  Person,
  Search,
} from '@mui/icons-material';
import { Chip, Input, Stack, Tooltip, Typography } from '@mui/joy';
import { keepPreviousData } from '@tanstack/react-query';
import { parseISO } from 'date-fns';
import { useState } from 'react';
import { RegistrationDtoVerificationState } from '../api/generated/model';
import { useListPatientRegistrationDetails } from '../api/generated/registration-detail-resource/registration-detail-resource.ts';
import { AdminHeader } from '../components/AdminHeader.tsx';
import { CheckboxButton } from '../components/CheckboxButton.tsx';
import { ContentSection } from '../components/ContentSection.tsx';
import { DecisionSelect } from '../components/DecisionSelect.tsx';
import { DocumentModal } from '../components/DocumentModal.tsx';
import { Pagination } from '../components/Pagination.tsx';
import { ScrollableTableContainer } from '../components/ScrollableTableContainer.tsx';
import { TableWithForm } from '../components/TableWithForm.tsx';
import { formatDate, formatDateWithTime } from '../utils/date.ts';
import { getSearchText } from '../utils/getSearchText.ts';

const PAGE_SIZE = 50;

interface OpenFileModal {
  open: boolean;
  registrationId: string;
  fileId: string;
}
const emptyOpenFileModal = { open: false, registrationId: '', fileId: '' };

export function ModelCheck() {
  const [showDoneRegistrations, setShowDoneRegistrations] = useState(false);
  const [page, setPage] = useState(0);
  const [documentModalOpen, setDocumentModalOpen] = useState<OpenFileModal>(emptyOpenFileModal);
  const [searchText, setSearchText] = useState('');

  const { data: pageDto, isRefetching: isPageDtoRefetching } = useListPatientRegistrationDetails(
    {
      page: page,
      size: PAGE_SIZE,
      showDone: showDoneRegistrations,
      searchParam: getSearchText(searchText),
    },
    { query: { placeholderData: keepPreviousData } },
  );

  return (
    <ContentSection>
      <AdminHeader>Überprüfung Versicherungsmodell</AdminHeader>
      <ScrollableTableContainer
        actionsLeft={
          <>
            <Input
              value={searchText}
              startDecorator={<Search />}
              placeholder={'Patienten suchen'}
              onChange={(event) => setSearchText(event.target.value)}
            />
            <CheckboxButton
              label={'Erledigte anzeigen'}
              checked={showDoneRegistrations}
              onChange={() => {
                setShowDoneRegistrations(!showDoneRegistrations);
              }}
            />
          </>
        }
        actionsRight={<Pagination pageDto={pageDto} page={page} setPage={setPage} pageSize={PAGE_SIZE} />}
        isLoading={isPageDtoRefetching}>
        <TableWithForm stickyHeader borderAxis="bothBetween">
          <thead>
            <tr>
              <th style={{ width: 240 }}>Name Vorname (eMail, Telefon Nr.)</th>
              <th>Praxis</th>
              <th style={{ width: 180 }}>Dokumente/Kommentar</th>
              <th style={{ width: 140 }}>Eingegangen am</th>
              <th style={{ width: 100 }}>Status</th>
              <th>Entscheid</th>
            </tr>
          </thead>
          <tbody>
            {pageDto?.registrationDetails && pageDto.registrationDetails.length > 0 ? (
              pageDto.registrationDetails.map((row) => (
                <tr key={row.registration.id}>
                  <td>
                    <div>
                      <Typography>
                        {row.registration.firstName} {row.registration.lastName}{' '}
                        <Typography fontSize={'small'}>({formatDate(parseISO(row.registration.birthDate))})</Typography>
                      </Typography>
                      <Typography>{row.registration.email}</Typography>
                      {row.registration.phoneNumber && <Typography>{row.registration.phoneNumber}</Typography>}
                    </div>
                  </td>
                  <td>
                    <Typography>{row.registration.requestedPractice.name}</Typography>
                    {row.registration.requestedPractice.addressLine2 && (
                      <Typography>{row.registration.requestedPractice.addressLine2}</Typography>
                    )}
                    {row.registration.requestedDoctor && (
                      <Typography>{row.registration.requestedDoctor.name}</Typography>
                    )}
                  </td>
                  <td>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                      {row.registration.comment && (
                        <Tooltip
                          title={<Typography sx={{ whiteSpace: 'pre-wrap' }}>{row.registration.comment}</Typography>}
                          variant={'outlined'}
                          size={'lg'}
                          arrow
                          sx={{ maxWidth: 300 }}>
                          <Chip sx={{ maxWidth: '100%', alignSelf: 'start' }} startDecorator={<Comment />}>
                            {row.registration.comment}
                          </Chip>
                        </Tooltip>
                      )}
                      {row.registrationFiles.map((file) => (
                        <Chip
                          key={file.id}
                          sx={{ maxWidth: '100%', alignSelf: 'start' }}
                          startDecorator={<FileOpen />}
                          onClick={() =>
                            setDocumentModalOpen({
                              open: true,
                              registrationId: row.registration.id,
                              fileId: file.id,
                            })
                          }>
                          {file.name}
                        </Chip>
                      ))}
                    </div>
                  </td>
                  <td>{formatDateWithTime(parseISO(row.registration.created!))}</td>
                  <td>
                    <VerificationState state={row.registration.verificationState} />
                  </td>
                  <td>
                    <Stack sx={{ gap: 0.5 }}>
                      <DecisionSelect registration={row.registration} />
                      {row.state && (
                        <>
                          <Chip sx={{ maxWidth: '100%', alignSelf: 'start' }} startDecorator={<Person />}>
                            {row.state.userName}
                          </Chip>
                          {row.state.stateData.internalComment && (
                            <Tooltip
                              title={
                                <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                                  {row.state.stateData.internalComment}
                                </Typography>
                              }
                              variant={'outlined'}
                              size={'lg'}
                              arrow
                              sx={{ maxWidth: 300 }}>
                              <Chip sx={{ maxWidth: '100%', alignSelf: 'start' }} startDecorator={<Comment />}>
                                {row.state.stateData.internalComment}
                              </Chip>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </Stack>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  <Typography level={'h3'}>Keine Daten</Typography>
                </td>
              </tr>
            )}
          </tbody>
        </TableWithForm>
      </ScrollableTableContainer>
      <DocumentModal
        open={documentModalOpen.open}
        onClose={() => setDocumentModalOpen(emptyOpenFileModal)}
        registrationId={documentModalOpen.registrationId}
        fileId={documentModalOpen.fileId}
      />
    </ContentSection>
  );
}

function VerificationState({ state }: { state: RegistrationDtoVerificationState }) {
  switch (state) {
    case RegistrationDtoVerificationState.INITIAL:
      return (
        <Typography color={'neutral'} startDecorator={<CircleOutlined style={{ fontSize: 'small' }} />}>
          Offen
        </Typography>
      );
    case RegistrationDtoVerificationState.UNCLEAR:
      return (
        <Typography color={'warning'} startDecorator={<HelpOutline style={{ fontSize: 'small' }} />}>
          Unklar
        </Typography>
      );
    default:
      return (
        <Typography color={'success'} startDecorator={<CheckCircleOutline style={{ fontSize: 'small' }} />}>
          Erledigt
        </Typography>
      );
  }
}
